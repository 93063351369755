<script setup lang="ts">
const { t } = useT();
const { open } = useAppModals();
const loginGuard = useLoginGuard();
const isGuest = useIsGuest();
const { data, SUMMER_THEME } = useScretchCardData({ immediate: !isGuest.value });

const getImagePath = computed(() => (SUMMER_THEME ? "/summer-theme" : ""));

const handleCardClick = () => {
	loginGuard({
		success: () => {
			navigateTo("/scratch-cards");
		},
		fail: () => open("LazyOModalSignup")
	});
};
const handleButtonClick = () => {
	loginGuard({
		success: () => {
			window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
		},
		fail: () => open("LazyOModalSignup")
	});
};
</script>

<template>
	<ACard
		:class="[{ summer: SUMMER_THEME }, 'card-scratch-cards']"
		width="584px"
		height="760px"
		:imgAttrs="{
			width: 584,
			height: 760,
			src: `/nuxt-img/scratch-cards${getImagePath}/promo/promo-bg.png`
		}"
		alt="scratch-card"
		@click="handleCardClick"
	>
		<template #topLine>
			<MPromotionTimer :timeLeft="data?.finishedAt" />
		</template>

		<template #bottom>
			<LazyNuxtImg
				class="logo"
				:src="`/nuxt-img/scratch-cards${getImagePath}/promo/logo-new.png`"
				width="230"
				height="145"
				quality="100"
				format="webp"
				alt="promo-logo"
			/>
			<AText variant="topeka" :modifiers="['semibold', 'center', 'uppercase']">
				{{ t("up to") }}
			</AText>
			<div class="prizes">
				<MPrizeFund variant="coins" icon="coins" is-svg :iconSize="24">
					<AText variant="tirana" class="prize-text" :modifiers="['semibold']">
						{{ numberFormat(Number(data?.prize?.coins)) }}
					</AText>
				</MPrizeFund>
				<MPrizeFund variant="entries" icon="entries" is-svg :iconSize="24">
					<AText variant="tirana" class="prize-text" :modifiers="['semibold']">
						{{ numberFormat(Number(data?.prize?.entries)) }}
					</AText>
					<AText class="free prize-text" :modifiers="['semibold', 'uppercase']"> {{ t("for free") }}</AText>
				</MPrizeFund>
			</div>
			<LazyNuxtImg
				class="img-scratch"
				:src="`/nuxt-img/scratch-cards${getImagePath}/promo/scratch.png`"
				:width="SUMMER_THEME ? '586' : '584'"
				:height="SUMMER_THEME ? '390' : '392'"
				alt="promo-scratch"
			/>
			<AButton class="button" variant="primary" size="xl" @click.stop="handleButtonClick">
				<AText variant="turin" :modifiers="['medium']">
					{{ t("Play Now!") }}
				</AText>
			</AButton>
		</template>
	</ACard>
</template>

<style scoped lang="scss">
.card-scratch-cards {
	width: 292px;
	height: 380px;

	.timer {
		position: relative;
		z-index: 3;
	}

	.logo {
		width: 115px;
		height: 72px;
		margin-bottom: gutter(0.5);
	}

	.img-scratch {
		position: absolute;
		bottom: 16px;
		left: 50%;
		transform: translateX(-50%);
		width: 280px;
		height: 186px;
		z-index: -1;
	}
}

.prizes {
	text-align: center;
	margin-bottom: gutter(16.75);
	.free {
		max-width: 36px;
	}
}

.prize-text {
	.summer & {
		text-shadow:
			0 10px 15px rgba(var(--corlu-rgb), 0.1),
			0 4px 6px rgba(var(--corlu-rgb), 0.05);
	}
}
button.primary {
	width: 100%;
	position: relative;
	z-index: 2;
}
</style>
